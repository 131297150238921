import {
  Paper,
  IconButton,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import "./bar.css";
import useBarController from "../../controllers/barController";
import Badge from "@mui/material/Badge";
import styled from "@mui/material/styles/styled";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { StoreContext } from "../../store/storeContext";
const Bar = () => {
  const {
    handleOpenSidebar,
    OpenScanQr,
    cartItems,
    width,
    validations,
    handlepushState,
  } = React.useContext(StoreContext);
  const StyledBadge = styled(Badge)(({ theme }) => ({}));
  const NavButton = ({ properties }) => {
    const isSelected = window.location.href.includes(properties.link);
    return (
      <>
        {" "}
        <Button
          variant="text"
          className={`NavBarNotification-landing-page-nav-button ${
            isSelected && "NavBarNotification-landing-page-nav-button-selected"
          }`}
          onClick={() => {
            if (properties.link.includes("landing-page/home")) {
              window.dispatchEvent(new CustomEvent("landingPageOpen"));
            }
            if (properties.redirect) {
              window.location.href = properties.link;
            } else {
              handlepushState(properties.link);
            }
          }}
        >
          {properties.text}
        </Button>
      </>
    );
  };

  return (
    <Paper
      square={false}
      elevation={0}
      className="NavBarNotification-paper-container-bar"
    >
      <Grid
        container
        columns={20}
        className="NavBarNotification-grid-container-bar"
      >
        <Grid xl={1} lg={1} md={1} sm={1} xs={1} />
        {width < 900 && validations.isLogged ? (
          <Grid
            md={2}
            sm={2}
            xs={3}
            style={{
              display: "flex",
              verticalAlign: "middle",
              justifyContent: "center",
            }}
          >
            <IconButton
              size="small"
              onClick={handleOpenSidebar}
              id="menu-sidebar"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        ) : null}

        {width < 900 ? (
          <Grid
            xl={3}
            lg={3}
            md={2}
            sm={2}
            xs={2}
            className="NavBarNotification-yms-logo-container"
          >
            <img
              class="logoNavbar"
              style={{ height: "60%", alignSelf: "center" }}
              src="https://staccportalimagesprd.blob.core.windows.net/yms-images/app-bar-images/CircleYms.png"
              alt="Supra Logo"
            />
          </Grid>
        ) : (
          <>
            {!validations.isLogged && (
              <Grid
                xl={1}
                lg={1}
                md={2}
                sm={2}
                xs={2}
                className="NavBarNotification-yms-logo-container"
              >
                <img
                  class="logoNavbar"
                  style={{ height: "50%", alignSelf: "center" }}
                  src="https://staccimagesfrontprd.blob.core.windows.net/draypowerimages/app-bar-images/draypowerYMS.svg"
                  alt="Supra Logo"
                />
              </Grid>
            )}
          </>
        )}

        {validations.isLogged ? (
          <Grid xl={1} lg={1} md={2} sm={1} xs={1} />
        ) : (
          <Grid xl={0} lg={0} md={3} sm={3} xs={3} />
        )}
        {width > 900 ? (
          <Grid
            xl={2}
            lg={2}
            className="NavBarNotification-qr-large-button-container"
            mobile-display-none
          >
            {validations.isLogged && validations.isYardOwner && (
              <Button
                variant="contained"
                startIcon={<QrCodeScannerIcon />}
                id="qrButton"
                onClick={() => OpenScanQr()}
              >
                Scan QR
              </Button>
            )}
          </Grid>
        ) : (
          <Grid
            md={1}
            sm={2}
            xs={2}
            style={{
              display: "flex",

              alignSelf: "center",
            }}
            mobile-display-none
          >
            {validations.isLogged && validations.isYardOwner && (
              <QrCodeScannerIcon
                className="NavBarNotification-qr-code-scanner-icon-large"
                onClick={(e) => OpenScanQr()}
              />
            )}
          </Grid>
        )}

        {width > 900 ? (
          <>
            <Grid
              xl={13}
              lg={validations.isLogged ? 12 : 11}
              md={validations.isLogged ? 10 : 9}
              sm={7}
              className={"NavBarNotification-grid-nav-buttons"}
            >
              {" "}
              <NavButton
                properties={{
                  text: "Landing",
                  link: "landing-page/home",
                }}
              />{" "}
              <NavButton
                properties={{
                  text: "Our Empties",
                  link: "https://stlinessummary.dray-power.com/",
                  redirect: true,
                }}
              />
              {validations.isLogged && (
                <NavButton
                  properties={{
                    text: "Home",
                    link: "HomeView",
                  }}
                />
              )}
              <NavButton
                properties={{
                  text: "Products",
                  link: "landing-page/products",
                }}
              />
              {/* <NavButton
              properties={{
                text: "",
                link: "",
              }}
            /> */}
              {/* <NavButton
              properties={{
                text: "Empties Summary",
                link: "HomeView",
              }}
            /> */}
            </Grid>
            <Grid
              item
              xl={3}
              lg={validations.isLogged ? 4 : 5}
              md={validations.isLogged ? 4 : 5}
              sm={5}
              className={"NavBarNotification-grid-badge-container"}
            >
              <IconButton
                aria-label="cart"
                onClick={(e) => handlepushState("landing-page/cart-products")}
              >
                <StyledBadge
                  badgeContent={cartItems ? cartItems : 0}
                  className="NavBarNotification-badge"
                >
                  <ShoppingCartIcon />
                </StyledBadge>
              </IconButton>
            </Grid>
          </>
        ) : (
          <Grid
            md={8}
            sm={8}
            xs={7}
            className={"NavBarNotification-grid-nav-buttons"}
          >
            <FormControl>
              <Select
                onChange={(e) => {
                  if (!e.target.value.includes("https")) {
                    handlepushState(e.target.value);
                  } else {
                    window.location.href = e.target.value;
                  }
                }}
                sx={{
                  borderRadius: "50%",
                  maxHeight: "48px",
                  maxWidth: "48px",
                }}
              >
                <MenuItem value={"landing-page/home"}>Landing</MenuItem>
                <MenuItem value={"https://stlinessummary.dray-power.com/"}>
                  Our Empties
                </MenuItem>
                {validations.isLogged && (
                  <MenuItem value={"HomeView"}>Home</MenuItem>
                )}
                <MenuItem value={"landing-page/products"}>Products</MenuItem>
                <MenuItem value={"landing-page/cart-products"}>Cart</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
export default Bar;
